import usePrevious from 'hooks/usePrevious'
import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'

export interface PathData {
  route: string
  search?: string
  title: string | ReactNode
}

export interface NavigationProviderValue {
  paths: PathData[]
  previousParams: string
  setPaths: (paths: PathData[]) => void
  pushPath: (path: PathData) => void
}

const NavigationContext = createContext<NavigationProviderValue>({
  paths: [],
  previousParams: '',
  setPaths: () => null,
  pushPath: () => null,
})

const useNavigation = () => useContext(NavigationContext)

const NavigationProvider = withRouter(({ children, location: { pathname, search } }: any) => {
  const [paths, setPaths] = useState<PathData[]>([])

  const handlePushPath = (path: PathData) => {
    setPaths([...paths, path])
  }

  const handleSetPaths = useCallback(
    (newPaths: PathData[]) => {
      if ((newPaths.length === 1 && newPaths[0]?.title === 'Hem') || newPaths.length === 0) {
        document.title = 'Cruitive'
      } else {
        document.title =
          typeof newPaths[newPaths.length - 1]?.title === 'string' ? `${newPaths[newPaths.length - 1]?.title} | Cruitive` : 'Laddar...'
      }
      setPaths(newPaths)
    },
    [setPaths]
  )

  const previousParams = usePrevious(search)

  return (
    <NavigationContext.Provider
      value={{
        paths,
        previousParams: previousParams || '',
        setPaths: handleSetPaths,
        pushPath: handlePushPath,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
})

export { NavigationProvider, NavigationContext, useNavigation }
