import { gql } from '@apollo/client'
import MeParts from 'api/fragments/MeParts'
import UserAgentParts from 'api/fragments/UserAgentParts'
import UserParts from 'api/fragments/UserParts'

export const CompanyUsersQuery = gql`
  query CompanyUsers(
    $companyId: ID!
    $limit: Int!
    $after: String
    $sortOrder: String
    $includeSelf: Boolean
    $sortBy: String
    $onlyInvited: Boolean
    $searchText: String
    $showResultsFromParentCompanies: Boolean
  ) {
    companyUsers(
      companyId: $companyId
      limit: $limit
      after: $after
      sortOrder: $sortOrder
      includeSelf: $includeSelf
      sortBy: $sortBy
      onlyInvited: $onlyInvited
      searchText: $searchText
      showResultsFromParentCompanies: $showResultsFromParentCompanies
    ) {
      edges {
        cursor
        node {
          id
          ...UserParts
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${UserParts}
`

export const InvitedUsersQuery = gql`
  query InvitedUsers($companyId: ID!, $limit: Int!, $after: String, $sortOrder: String, $sortBy: String) {
    invitedUsers(companyId: $companyId, limit: $limit, after: $after, sortOrder: $sortOrder, sortBy: $sortBy) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          createdAt
          firstName
          lastName
          email
          privilegeLevel
        }
      }
    }
  }
`

export const UserQuery = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      ...UserParts
    }
  }
  ${UserParts}
`

export const UsersQuery = gql`
  query Users($companyId: ID!, $recruitmentId: ID, $searchText: String, $includeSelf: Boolean, $limit: Int) {
    users(companyId: $companyId, recruitmentId: $recruitmentId, searchText: $searchText, includeSelf: $includeSelf, limit: $limit) {
      id
      ...UserParts
    }
  }
  ${UserParts}
`

export const MeQuery = gql`
  query Me {
    me {
      id
      ...MeParts
    }
  }
  ${MeParts}
`

export const UsersAdminQuery = gql`
  query UsersAdmin($page: Int, $pageSize: Int, $searchText: String, $sortOrder: String, $sortBy: String) {
    usersAdmin(page: $page, pageSize: $pageSize, searchText: $searchText, sortOrder: $sortOrder, sortBy: $sortBy) {
      totalCount
      items {
        id
        ...UserParts
        userAgents {
          id
          ...UserAgentParts
        }
      }
    }
  }
  ${UserParts}
  ${UserAgentParts}
`
