import { gql } from '@apollo/client'

export default gql`
  fragment UserAgentParts on UserAgent {
    id
    userAgent
    createdAt
    updatedAt
    usageCount
  }
`
