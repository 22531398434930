import { gql } from '@apollo/client'
import CompanyParts from 'api/fragments/CompanyParts'

export default gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      ...CompanyParts
    }
  }
  ${CompanyParts}
`
