import ServerDownImage from 'assets/server_down.svg'
import Button from 'base-components/Button'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdUpdate } from 'react-icons/md'
import { useNetworkState } from 'react-use'

const ErrorUpdateMessage: FC = () => {
  const { t } = useTranslation()
  const { online: _online } = useNetworkState()
  const [online, setOnline] = useState<null | undefined | boolean>(null)

  useEffect(() => {
    if (online == null || online === undefined) {
      setOnline(_online)
    }
  }, [online, _online])

  const [loading, setLoading] = useState(false)
  const handleUpdate = () => {
    setLoading(true)
    window.location.reload()
  }

  if (online == null) {
    return <div />
  }

  return (
    <div className='flex-center min-h-screen bg-gray-200 p-12'>
      <div className='flex max-w-2xl flex-col items-center'>
        {online && <img src={ServerDownImage} alt='Server down' />}
        <h1 className='mt-10 text-center text-5xl font-bold text-gray-800'>
          {online && t('general.cruitive_has_been_updated')}
          {!online && t('general.something_went_wrong')}
        </h1>
        <p className='mt-4 text-center text-xl text-gray-500'>
          {online && t('general.cruitive_has_been_updated_and_need_to_reload_description')}
          {!online && t('general.something_went_wrong_with_the_internet_when_data_was_loading_description')}
        </p>
        <Button disabled={loading} onClick={handleUpdate} size='xl' className='mt-6 w-full max-w-lg text-xl'>
          <MdUpdate className={`mr-2 ${loading && 'animate-spin'}`} />
          {t('general.reload_page')}
        </Button>
      </div>
    </div>
  )
}

export default ErrorUpdateMessage
