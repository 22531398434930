import { gql } from '@apollo/client'

export default gql`
  fragment EnabledFeaturesParts on EnabledFeatures {
    id
    assignments
    calendar
    consultantProjects
    contracts
    screeningForms
    referenceForms
    trustcruit
    applicationsRankedWithAI
    interviewBooking
    interviewBookingGroups
    alvaLabs
    tengai
    allApplicationsPage
    aiGeneration
    careerPage
    mediaLibrary
    jobtip
    evaluationForm
    statusDeadlines
    videoInApplications
  }
`
