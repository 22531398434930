import { gql } from '@apollo/client'
import EnabledFeaturesParts from './EnabledFeaturesParts'

export default gql`
  fragment CompanyParts on Company {
    id
    createdAt
    name
    slug
    subdomain
    orgNumber
    primaryColor
    language
    shortPresentation
    postalCode
    postalAddress
    municipality
    websiteUrl
    type
    applicationCount
    users {
      id
      firstName
      lastName
      profileImage {
        id
        url
      }
    }
    parentCompany {
      id
      name
      parentCompany {
        id
        name
      }
    }
    profileImage {
      id
      url
    }
    companyFont {
      id
      url
      filename
    }
    companyWebFont
    titleFont
    textFont
    apiKey
    childCompanies {
      id
    }
    defaultRecruitmentTemplate {
      id
    }
    enabledFeatures {
      id
      ...EnabledFeaturesParts
    }
  }
  ${EnabledFeaturesParts}
`
