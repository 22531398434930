import { FC, PropsWithChildren, useMemo, useState } from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'
// import translationEN from '../i18n/en/resource.json'
// import translationSV from '../i18n/sv/resource.json'
import i18n from 'i18next'
import { useAsync } from 'react-use'
import { useMe } from '../providers/MeProvider'
import LoadingHome from './LoadingHome'

export const init = async (language: 'sv' | 'en') => {
  const resources = await import(`../i18n/${language}/resource.json`)
  i18n.use(initReactI18next).init({
    resources: { [language]: resources.default },
    lng: language,
  })
}

const TranslationsWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { language: _language } = useMe()
  const language = useMemo(() => _language || 'sv', [_language])

  const [initialized, setInitialized] = useState(false)
  useAsync(async () => {
    if (!i18n.isInitialized) {
      const resources = (await import(`../i18n/${language}/resource.json`)).default

      i18n.use(initReactI18next).init({
        resources: { [language]: { translation: resources } },
        returnNull: false,
        lng: language,
      })

      setInitialized(true)
    } else if (i18n.language !== language) {
      const resource = (await import(`../i18n/${language}/resource.json`)).default
      i18n.addResourceBundle(language, 'translation', resource)
      await i18n.changeLanguage(language)
    }
  }, [language])

  if (!initialized) {
    return <LoadingHome />
  }

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export default TranslationsWrapper
