import { Auth0Provider } from '@auth0/auth0-react'
import { init } from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import CoreLayout from 'base-components/CoreLayout'
import ErrorBoundary from 'components/ErrorBoundary'
import LoadingHome from 'components/LoadingHome'
import TranslationProvider from 'components/TranslationsWrapper'
import ApolloProviderWithAuth0 from 'providers/ApolloProviderWithAuth0'
import { CompanyProvider } from 'providers/CompanyProvider'
import { MeProvider } from 'providers/MeProvider'
import { NavigationProvider } from 'providers/NavigationProvider'
import { FC, Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Route, Router, Switch } from 'react-router-dom'
import 'styles/index.css'
import { QueryParamProvider } from 'use-query-params'
import getUrls from 'utils/getUrls'
import lazyWithRetry from 'utils/lazyWithRetry'

import { createBrowserHistory } from 'history'

const AdminRoute = lazyWithRetry(() => import('routes/Admin/AdminRoute'))
const SignupRoute = lazyWithRetry(() => import('routes/SignupRoute'))
const UserNotFoundRoute = lazyWithRetry(() => import('routes/UserNotFoundRoute'))
const LoginRoute = lazyWithRetry(() => import('routes/LoginRoute'))
const AppRoute = lazyWithRetry(() => import('routes/AppRoute'))

// eslint-disable-next-line
console.log('Version:', process.env.REACT_APP_VERSION, getUrls().currentUrl)

if (process.env.NODE_ENV === 'production') {
  init({
    dsn: 'https://5617b598c4ba48eaaa4339b11bed0137@o304107.ingest.sentry.io/1757675',
    integrations: [new BrowserTracing()],
    release: `cruitive-app@${process.env.REACT_APP_VERSION}`,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    beforeSend(event: any, hint: any) {
      /* tslint:disable:no-string-literal only-arrow-functions */
      const isNonErrorException =
        event?.exception?.values?.[0].value?.startsWith('Non-Error exception captured') ||
        hint?.originalException?.['message']?.startsWith('Non-Error exception captured')
      /* tslint:enable:no-string-literal only-arrow-functions */

      if (isNonErrorException) {
        return null
      }
      return event
    },
  })
}

const urls = getUrls()

if (typeof (window as any).global === 'undefined') {
  ;(window as any).global = window
}

const Index: FC = () => {
  return (
    <Router history={createBrowserHistory()}>
      <Auth0Provider
        clientId='ODGMaMTOt27fNG1nFDGPcySrHx37H1OC'
        domain='auth.cruitive.com'
        authorizationParams={{
          redirect_uri: urls.currentUrl,
          audience: 'https://server.cruitive.com',
        }}
      >
        <ApolloProviderWithAuth0>
          <>
            {/* <CssBaseline /> */}
            <QueryParamProvider ReactRouterRoute={Route}>
              <SkeletonTheme>
                <CoreLayout>
                  <MeProvider>
                    <CompanyProvider>
                      <TranslationProvider>
                        <NavigationProvider>
                          <ErrorBoundary>
                            <Suspense fallback={<LoadingHome />}>
                              <Switch>
                                <Route path='/login' component={LoginRoute} />
                                <Route path='/signup' component={SignupRoute} exact={true} />
                                <Route path='/user-not-found' component={UserNotFoundRoute} exact={true} />
                                <Route path='/admin' component={AdminRoute} />
                                <Route path='/' component={AppRoute} />
                              </Switch>
                            </Suspense>
                          </ErrorBoundary>
                          <Toaster
                            toastOptions={{
                              style: {
                                backgroundColor: '#374151',
                                color: '#fff',
                                padding: '0.75rem',
                                zIndex: 11000,
                                fontSize: '.875rem',
                              },
                              success: {
                                iconTheme: {
                                  primary: '#3DB6A8',
                                  secondary: '#fff',
                                },
                              },
                              error: {
                                iconTheme: {
                                  primary: '#ef4444',
                                  secondary: '#fff',
                                },
                              },
                            }}
                          />
                        </NavigationProvider>
                      </TranslationProvider>
                    </CompanyProvider>
                  </MeProvider>
                </CoreLayout>
              </SkeletonTheme>
            </QueryParamProvider>
          </>
        </ApolloProviderWithAuth0>
      </Auth0Provider>
    </Router>
  )
}

export default Index