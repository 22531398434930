import { gql } from '@apollo/client'

export default gql`
  fragment UserParts on User {
    id
    auth0Id
    createdAt
    updatedAt
    firstName
    lastName
    email
    phoneNumber
    jobTitle
    privilegeLevel
    profileImage {
      id
      url
    }
  }
`
