import { gql } from '@apollo/client'
import EnabledFeaturesParts from './EnabledFeaturesParts'

export default gql`
  fragment MeParts on User {
    id
    firstName
    lastName
    email
    language
    phoneNumber
    jobTitle
    profileImage {
      id
      url
    }
    company {
      id
      name
      apiKey
      language
      type
      childCompanies {
        id
        childCompanies {
          id
        }
      }
      parentCompany {
        id
        name
      }
      profileImage {
        id
        url
      }
      enabledFeatures {
        id
        ...EnabledFeaturesParts
      }
    }
    privilegeLevel
    subscribedMailNotificationTypes
    subscribedToNotificationsStatus
  }
  ${EnabledFeaturesParts}
`
