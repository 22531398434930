import useIsMobile from 'hooks/useIsMobile'
import { ButtonHTMLAttributes, forwardRef, PropsWithChildren, useMemo } from 'react'
import ButtonBase from './ButtonBase'
import Spinner from './Spinner'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'red'
  variant?: 'primary' | 'secondary' | 'third'
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl'
  className?: string
  loading?: boolean
}

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  ({ children, size = 'base', variant = 'primary', loading, type = 'button', color = 'primary', className, ...rest }, ref) => {
    const baseClass = `flex items-center justify-center font-semibold border focus:outline-none transition ease-in-out duration-150 button-focus`

    const variantClass = useMemo(() => {
      if (variant === 'primary') {
        let className = 'shadow-sm text-white '

        if (color === 'primary') {
          className += 'bg-gradient focus:border-primary-500 focus:shadow-outline-blue active:bg-primary-700 hover:bg-primary-500'
        } else if (color === 'red') {
          className = 'bg-red-600 focus:border-red-500 focus:shadow-outline-red active:bg-red-700 hover:bg-red-500'
        }

        return className
      }

      if (variant === 'secondary') {
        if (color === 'primary') {
          return 'border-primary-200 border text-primary-700 bg-primary-100 hover:bg-primary-50 focus:shadow-outline-blue active:bg-primary-200'
        } else if (color === 'red') {
          return 'border-red-200 border text-red-700 bg-red-100 hover:bg-red-50 focus:shadow-outline-red active:bg-red-200'
        }
      }

      if (variant === 'third') {
        return 'border border-gray-300 text-gray-600 hover:text-gray-500 focus:outline-none focus:border-primary-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150'
      }
    }, [variant, color])

    const isMobile = useIsMobile()
    const sizeClass = useMemo(() => {
      let size_ = size
      if (isMobile) {
        if (size === 'xs') {
          return 'px-2 p-1 text-[11px] leading-3 rounded-md'
        } else if (size === 'sm') {
          size_ = 'xs'
        } else if (size === 'base') {
          size_ = 'sm'
        } else if (size === 'lg') {
          size_ = 'base'
        } else if (size === 'xl') {
          size_ = 'lg'
        }
      }

      if (size_ === 'xs') {
        return 'px-2.5 p-1 text-xs leading-4 rounded-md'
      }

      if (size_ === 'sm') {
        return 'px-2.5 py-1.5 text-xs leading-5 rounded-md'
      }

      if (size_ === 'base') {
        return 'px-3 py-1.5 text-sm leading-5 rounded-md'
      }

      if (size_ === 'lg') {
        return 'px-4 py-2 text-base leading-6 rounded-md'
      }

      if (size_ === 'xl') {
        return 'px-6 py-3 text-base leading-6 rounded-md'
      }
    }, [size, isMobile])

    return (
      <ButtonBase className={`${className} ${baseClass} ${variantClass} ${sizeClass} ${rest.disabled && 'opacity-50'}`} {...rest} ref={ref}>
        {loading && <Spinner size={10} style={{ marginRight: 10 }} />}
        {children}
      </ButtonBase>
    )
  }
)

export default Button
