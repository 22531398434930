import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import CompanyQuery from 'api/queries/Company/CompanyQuery'
import { COMPANY_TYPE, Company, CompanyVariables, Company_company } from 'generatedTypes'
import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useApolloWithAuth0 } from './ApolloProviderWithAuth0'
import { useMe } from './MeProvider'

const initialCompany: any = {
  __typename: 'Company',
  id: '',
  name: '',
  subdomain: '',
  orgNumber: '',
  primaryColor: '',
  shortPresentation: '',
  postalCode: '',
  language: 'sv',
  postalAddress: '',
  municipality: '',
  websiteUrl: '',
  apiKey: '',
  type: null,
  profileImage: null,
  defaultRecruitmentTemplate: null,
  companyFont: null,
  companyWebFont: '',
  titleFont: '',
  textFont: '',
  recruitments: [],
  users: [],
  parentCompany: null,
  enabledFeatures: {
    __typename: 'EnabledFeatures',
    assignments: false,
    calendar: false,
    consultantProjects: false,
    contracts: false,
    screeningForms: false,
    referenceForms: false,
    trustcruit: false,
    applicationsRankedWithAI: false,
    alvaLabs: false,
    interviewBookingGroups: false,
    tengai: false,
    jobtip: false,
  },
}

const CompanyContext = createContext<CompanyProviderValue>({
  activeCompany: { ...initialCompany },
  groupCompany: { ...initialCompany },
  activeCompanyHasParentCompany: false,
  isViewingGroupCompany: false,
})

interface CompanyProviderValue {
  activeCompany: Company_company
  activeCompanyHasParentCompany: boolean
  groupCompany: Company_company | null
  isViewingGroupCompany: boolean
}

const CompanyProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth0()
  const { company: myCompany } = useMe()
  const { pathname } = useLocation()

  const [activeCompanyHasParentCompany, setActiveCompanyHasParentCompany] = useState<boolean>(false)
  const [activeCompanyId, setActiveCompanyId] = useState<string>(myCompany.id)
  const [isViewingGroupCompany, setIsViewingGroupCompany] = useState(false)

  useEffect(() => {
    // Check the URL to determinie if we are viewing a group company, regular company or child company
    const customMatch: any = matchPath(pathname, {
      path: `/company/:companyId`,
      exact: false,
      strict: true,
    })

    if (customMatch?.params?.companyId) {
      setActiveCompanyId(customMatch?.params?.companyId)
      setIsViewingGroupCompany(false)
    } else {
      if (myCompany.type === COMPANY_TYPE.GROUP || myCompany.type === COMPANY_TYPE.GROUP_EVENT_MATCHING) {
        setActiveCompanyId(myCompany?.id)
        setIsViewingGroupCompany(true)
      }
    }
  }, [pathname, myCompany])

  const handleSetActiveCompany = (companyData: Company) => {
    if (companyData?.company?.parentCompany) {
      setActiveCompanyHasParentCompany(true)
    } else {
      setActiveCompanyHasParentCompany(false)
    }
  }

  const initialValue = useMemo<CompanyProviderValue>(
    () => ({
      activeCompany: { ...initialCompany },
      groupCompany: { ...initialCompany },
      isViewingGroupCompany,
      activeCompanyHasParentCompany,
    }),
    [isViewingGroupCompany, activeCompanyHasParentCompany]
  )

  // Get company from url
  const { data: activeCompany } = useQuery<Company, CompanyVariables>(CompanyQuery, {
    skip: !isAuthenticated || !activeCompanyId,
    variables: { id: activeCompanyId },
    onCompleted: handleSetActiveCompany,
  })

  // Get company from useMe
  const { data: rootCompany } = useQuery<Company, CompanyVariables>(CompanyQuery, {
    skip: !isAuthenticated || !myCompany?.id,
    variables: { id: myCompany.id },
  })

  const { activeCompanyId: apolloActiveCompanyId, setActiveCompanyId: apolloSetActiveCompanyId } = useApolloWithAuth0()

  const value = useMemo<CompanyProviderValue>(() => {
    if (Boolean(rootCompany) || Boolean(activeCompany)) {
      return {
        activeCompany: activeCompany?.company ?? rootCompany?.company ?? initialCompany,
        groupCompany:
          rootCompany?.company?.type === COMPANY_TYPE.GROUP || rootCompany?.company?.type === COMPANY_TYPE.GROUP_EVENT_MATCHING
            ? rootCompany.company
            : null,
        isViewingGroupCompany,
        activeCompanyHasParentCompany,
      }
    } else {
      return initialValue
    }
  }, [activeCompany, rootCompany, isViewingGroupCompany, initialValue, activeCompanyHasParentCompany])

  useEffect(() => {
    if (value.activeCompany.id !== '' && value.activeCompany.id !== apolloActiveCompanyId) {
      apolloSetActiveCompanyId(value.activeCompany.id)
    } else if (!value.activeCompany) {
      apolloSetActiveCompanyId(undefined)
    }
  }, [value.activeCompany, apolloActiveCompanyId, apolloSetActiveCompanyId])

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
}

const useCompany = () => useContext(CompanyContext)

export { CompanyProvider, useCompany }
